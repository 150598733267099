<template>
  <div class="topbar-item">
    <b-dropdown
      variant="link"
      toggle-class="custom-v-dropdown"
      no-caret
      right
      no-flip
      text="İşlemler"
    >
      <!-- v-b-tooltip.hover="'Hızlı İşlemler'" -->
      <template v-slot:button-content>
        <a
          href="#"
          class="d-flex align-items-center text-dark-50 text-hover-dark bg-hover-light p-3 text-underline-none"
          data-toggle="dropdown"
        >
          <i class="far fa-user"></i>

          <div class="text-left mx-3">
            <div class="font-size-sm font-weight-bold">
              {{ getUserFullName() }}
            </div>
            <div class="font-size-xs font-weight-light">
              {{ getUserRoleText() }}
            </div>
          </div>

          <i class="fa fa-chevron-down"></i>
        </a>
      </template>
      <!--begin::Navigation-->
      <div class="navi navi-hover min-w-md-250px">
        <b-dropdown-text tag="div" class="navi-item">
          <a href="/bize-ulasin" class="navi-link">
            <span class="navi-icon">
              <i class="flaticon-chat-1"></i>
            </span>
            <span class="navi-text">Bize Ulaşın</span>
          </a>
        </b-dropdown-text>
        <b-dropdown-text
          tag="div"
          class="navi-separator mt-3"
        ></b-dropdown-text>
        <b-dropdown-text tag="div" class="navi-footer">
          <button class="btn btn-light-danger w-100" @click="onLogout">
            <i class="fas fa-power-off mr-2"></i> Çıkış Yap
          </button>
        </b-dropdown-text>
      </div>
      <!--end::Navigation-->
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LOGOUT } from '@/core/services/store/auth.module';
import DPLayoutQuickUser from '@/assets/js/layout/extended/quick-user.js';

export default {
  name: 'DPQuickUser',
  mounted() {
    // Init Quick User Panel
    DPLayoutQuickUser.init(this.$refs['dp_quick_user']);
  },
  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => (window.location = '/giris'));
    },
    getUserFullName() {
      if (!this.currentUser || !this.currentUser.fullName) return;

      return this.currentUser.fullName;
    },
    getUserRoleText() {
      if (!this.currentUser || !this.currentUser.fullName) return;

      return this.$customFunctions.getUserTypeText(this.currentUser.user_group);
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
};
</script>

<style lang="scss" scoped>
#dp_quick_user {
  overflow: hidden;
  height: 230px;
  #dp_quick_user_close {
    i {
      font-size: 15px;
    }
  }
}
</style>
